import React from "react";
import Footer from "src/components/Footer_uk";

class Page extends React.Component {
  render() {
    return (
      <div>
        <script
          src="//tt.mbww.com/tt-b028e7b30ae6629575651e0369e15c482a1b97a7d437334f1b1cf00f5c06a78f.js"
          async
        />
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/uk/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/uk/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/uk/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>                      
                      <li>
                        <a href="/uk/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/uk/our-products/allergy/">Allergy</a>
                      </li>                      
                      <li>
                        <a href="/uk/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/aftersun/">After sun</a>
                      </li>                      
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/where-to-buy/" target="_self">
                      Where to buy
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/private-policy/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/private-policy/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/politicia-de-privacidad/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/politica_de_privacidade/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/private-policy/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/private-policy/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h1>PRIVACY POLICY</h1>
                <p>
                  <em>Last updated: 04.05.2023</em>
                </p>
                <p> </p>
                <p>
                  <strong>Johnson &amp; Johnson Limited</strong> cares about your privacy and wants 
                  you to be familiar with how we collect, use, and disclose information. 
                  This Privacy Policy describes our practices in connection with information that 
                  we or our service providers collect through the website or application (hereinafter the “Service”) 
                  operated and controlled by us from which you are accessing this Privacy Policy. 
                  By providing personal information to us or by using the Service, 
                  you acknowledge that you have read and understand this Privacy Policy.
                </p>
                <p> </p>
                <h2>USE BY MINORS</h2>
                <p>The Service is not directed to individuals under 
                  the age of 13, and we request that these individuals 
                  not provide personal information through the Service. 
                  If your child has submitted Personal Information and you 
                  would like to request that such Personal Information be 
                  removed, please contact us as explained below under Contacting Us.
                </p>
                <p></p>
                <h2>INFORMATION COLLECTION</h2>
                
                <p>
                We may ask you to submit personal information in order for you to benefit from certain 
                features (such as newsletter subscriptions, tips/pointers, or order processing) or to 
                participate in a particular activity (such as sweepstakes or other promotions).  
                You will be informed what information is required and what information is optional.
                </p>
                <p>
                We may combine the information you submit with other information we have 
                collected from you, whether on  or offline, including, for example, your purchase history.  
                We may also combine it with information we receive about you from other Kenvue 
                affiliates and the following other sources: publicly available information sources 
                (including information from your publicly available social media profiles), and other third parties.
                </p>
                <p>If you submit any personal information relating to another individual to us, 
                  you represent that you have the authority to do so 
                  and to permit us to use the information in accordance with this Privacy Policy.</p>
                <p>
                  <strong>SENSITIVE INFORMATION</strong>
                </p>
                <p>
                Unless we specifically request or invite it, we ask that you not send us, and you 
                not disclose, any sensitive personal information (e.g., Social Security numbers, 
                information related to racial or ethnic origin, political opinions, religion or 
                philosophical beliefs, health or medical conditions, sex life or sexual orientation, 
                criminal background, or trade union membership, or 
                biometric or genetic data) on or through the Service or otherwise to us.
                </p>
                
                <p>
                  <strong>AUTOMATIC INFORMATION COLLECTION AND USE</strong>
                </p>
                <p>
                We and our service providers may collect certain information automatically as you 
                navigate around the Service.  Please read the Cookie Policy for detailed 
                information about the cookies and other tracking technologies used on the Service.  
                Except where allowed by applicable law, we place cookies after having received 
                your consent through the cookie banner or preference center. 
                The <a href="uk/legal/cookie-policy/">Cookie Policy</a> includes information on how you may disable these technologies.
                </p>
                <p>We and our service providers may also automatically collect and use information in the following ways:</p>
                <p>
                  <strong>Through your browser:</strong>
                  </p>
                  <p>  Certain information is collected by most browsers, such as your Media Access Control (MAC) address, 
                    computer type (Windows or Mac), screen resolution, operating system name and version, and 
                    Internet browser type and version.  We may collect similar information, such as your device 
                    type and identifier, if you access the Service through a mobile device.  We use this 
                    information to ensure that the Service functions properly.
                    </p>
                  <p><strong>IP address:</strong></p>  
                  <p>Your IP address is a number that is automatically assigned to your 
                    computer by your Internet Service Provider.  An IP address is identified 
                    and logged automatically in our server log files whenever a user visits the 
                    Service, along with the time of the visit and the pages visited.  Collecting 
                    IP addresses is standard practice and is done automatically by many online services. 
                    We use IP addresses for purposes such as calculating Service usage levels, 
                    diagnosing server problems, and administering the Service.  We may also derive 
                    your approximate location from your IP address.</p>
                    <p>
                      <strong>
                      Device Information:
                      </strong>
                    </p>
                    <p> We may collect information about your mobile device, such as a unique device 
                      identifier, to understand how you use the Service.
                    </p> 
                    <p><h2> HOW WE USE AND DISCLOSE INFORMATION</h2></p>
                    <p> We use and disclose information you provide to us as described to you at 
                        the point of collection.  Please see the section entitled “Choices and Access,” 
                        below, to learn how you may opt out of certain of our uses and disclosures.
                        Where required by applicable law, we will obtain your consent to our use of your personal 
                        information at the point of information collection.  We may also use information from or 
                        about you as necessary to perform a contract, to comply with a legal obligation (for example, 
                        due to our pharmacovigilance obligations), or for our legitimate business interests.  
                        We may also rely on other legal bases, specifically for:
                    </p>
                    <p>
                    <ul>
                      <li><strong>Providing the functionality of the Service and fulfilling your requests.</strong></li>
                      <li>to provide the functionality of the Service to you and providing you with related customer service;</li>
                      <li>to respond to your inquiries and fulfill your requests, such as to send you documents you request or e mail alerts;</li>
                      <li>to send you important information regarding our relationship with you or regarding the Service, changes to our terms, conditions, and policies and/or other administrative information.</li>
                    </ul>
                    </p>
                    <p>We will engage in these activities to manage our contractual relationship with you and/or to comply with a legal obligation.</p>

                   <p>
                   <ul>
                     <li><strong>Accomplishing our business purposes.</strong></li>

                     <li>for data analysis, for example, to improve the efficiency of the Service;</li>
                     <li>for audits, to verify that our internal processes function as intended and are compliant with legal, regulatory, or contractual requirements;
                     </li>
                     <li>	for fraud and security monitoring purposes, for example, to detect and prevent cyberattacks or attempts to commit identity theft;
                      </li>
                     <li>	for developing new products and services;</li>
                     <li> for enhancing, improving or modifying our website or products and services;</li>
                     <li> for identifying Service usage trends, for example, understanding which parts of our Service are of most interest to users; and
                    </li>
                    <li> for determining the effectiveness of our promotional campaigns, so that we can adapt our campaigns to the needs and interests of our users.
                    </li>
                  </ul>
                </p>
<p>We will engage in these activities to manage our contractual relationship 
  with you, to comply with a legal obligation, and/or because we have a legitimate interest.</p>

<p>  
<ul>
<li><strong> Analysis of Personal Information to provide personalized services.</strong></li>
<li>	to better understand you, so that we can personalize our interactions with you and provide you with information and/or offers tailored to your interests;
</li>
<li>to better understand your preferences so that we can deliver content via the Service that we believe will be relevant and interesting to you.
We will provide personalized services either with your consent or because we have a legitimate interest.</li>
</ul>
</p>
<p>
<ul>
<li><strong>We also disclose information collected through the Service:</strong></li>
<li>	to our affiliates for the purposes described in this Privacy Policy. 
   A list of our affiliates is available at <a target="_blank" href="https://investors.kenvue.com/financials-reports/sec-filings/default.aspx">https://investors.kenvue.com/financials-reports/sec-filings/default.aspx </a>
   - access Kenvue’s subsequent filings with the Securities Exchange Commission.  
   Johnson &amp; Johnson Limited is the party responsible for the management of the jointly used Personal Information;</li>
<li>	to our third party partners with whom we offer a co branded or co marketed promotion;</li>
<li>	to our third party service providers who provide services such as website hosting and 
moderating, mobile application hosting, data analysis, payment processing, order fulfillment, 
infrastructure provision, IT services, customer service, e mail and direct mail delivery services, 
auditing, and other services, in order to enable them to provide services; and</li>
<li>as permitted by applicable law, to a third party in the event of any reorganization, merger, sale,
   joint venture, assignment, transfer, or other disposition of all or any portion of our business, assets, 
   or stock (including in connection with any bankruptcy or similar proceedings).</li>
</ul>
</p>

<p>
In addition, we may use and disclose your information as we believe to be necessary or appropriate: (a) to comply with legal process or applicable law, which may include laws outside your country of residence; (b) as permitted by applicable law to respond to requests from public and government authorities, which may include authorities outside your country of residence; (c) to enforce our terms and conditions; and (d) to protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or others.  We may also use and disclose your information in other ways after obtaining your consent to do so.
We may use and disclose information we collect automatically as described above, under “Automatic Information Collection and Use.” 
In addition, where allowed by applicable law, we may use and disclose information that is not in personally identifiable form for any purpose.  If we combine information that is not in personally identifiable form with information that is identifiable (such as combining your name with your geographical location), we will treat the combined information as personal information as long as it is combined.
</p>
<p><strong>CHOICES AND ACCESS</strong></p>
<p>Your choices regarding our use and disclosure of your personal information</p>
<p>We give you choices regarding our use and disclosure of your personal information for marketing purposes.
    You may opt out from:</p>
    <ul>
      <li>
      <strong>Receiving marketing communications from us:</strong>  If you no longer want to receive marketing communications from us on a going forward basis, you may opt out of receiving them by contacting us via sending an <a target="_blank" href="https://globalconsumercare.com/ContactUs/s/ContactForm?language=en_US&id=a296N000000Bsi2">email</a>.  In your request to us, please provide your name, identify the form(s) of marketing communications that you no longer wish to receive, and include the address(es) to which it/they are sent.  For example, if you no longer wish to receive marketing e mails or direct mail from us, tell us that, and provide your name and e mail or postal address
      </li>
 
<li><strong>Receiving reminders from us:</strong>  If you no longer want to receive medical reminders from us on a going forward basis, you may opt out of receiving them by contacting us via sending an <a target="_blank" href="https://globalconsumercare.com/ContactUs/s/ContactForm?language=en_US&id=a296N000000Bsi2">email</a>.  In your response to us, please provide your name and the e mail address or phone number at which you receive reminders from us.
</li>
<li><strong>Our sharing of your personal information with affiliates and third party partners:</strong>  If you previously opted-in to receiving marketing communications from our affiliates or third party partners, you may opt out of our sharing of your personal information with those parties for their direct marketing purposes on a going forward basis by contacting us via sending an <a target="_blank" href="https://globalconsumercare.com/ContactUs/s/ContactForm?language=en_US&id=a296N000000Bsi2">email</a>.  In your communication to us, please state that we should no longer share your personal information with our affiliates and/or third party partners for their marketing purposes, and include your name and e mail address.
</li>
</ul>
<p>We will seek to comply with your request(s) as soon as reasonably practicable.  
  Please note that if you opt out as described above, we will not be able to remove 
  your personal information from the databases of our affiliates with which we have 
  already shared your information (i.e., as of the date that we implement your opt out request). 
  However, we will make reasonable efforts to inform our affiliates of your request. 
  Please also note that if you opt out of receiving marketing related messages from us, 
  we may still send you important transactional and administrative messages, from which you cannot opt out.
  </p>
<p>How you can access, change, or delete your personal information</p>
<p>If you would like to review, correct, update, restrict, or delete your personal information, 
  or if you would like to request an electronic copy of your personal information for purposes of 
  transmitting it to another company (to the extent these rights are provided to you by applicable law), 
  please contact us via sending an <a target="_blank" href="https://globalconsumercare.com/ContactUs/s/ContactForm?language=en_US&id=a296N000000Bsi2">email</a>. We will respond to your request as soon 
  as reasonably practicable and no later than one month after receipt. If circumstances 
  cause any delay in our response, you will be promptly notified and provided a date for our response.
  </p>
  <p>
<strong>CROSS BORDER TRANSFER</strong>
<p>Your personal information may be stored and processed in any country where we have facilities or service providers, and by using our Service or by providing consent to us (where required by law), your information may be transferred to countries outside of your country of residence, including to the United States, which may provide for different data protection rules than in your country. Nonetheless, appropriate contractual and other measures are in place to protect personal information when it is transferred to our affiliates or third parties in other countries.
Some non-European Economic Area (EEA) countries are recognized by the European Commission as providing an adequate level of data protection according to EEA standards (the full list of these countries is available here).  For transfers from the EEA to countries not considered adequate by the European Commission, we have ensured that adequate measures are in place, including by ensuring that the recipient is bound by EU Standard Contractual Clauses, to protect your Personal Information.  You may obtain a copy of these measures by contacting our data protection officer in accordance with the “Contacting Us” section below.
</p>
</p>
<p><strong>SECURITY</strong> 
<p>We seek to use reasonable organizational, technical, and administrative measures designed to protect personal information under our control.  Unfortunately, no data transmission over the Internet or data storage system can be guaranteed to be 100% secure.  If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account you have with us has been compromised), please immediately notify us in accordance with the “Contacting Us” section below.
</p>
</p>
<p><strong>RETENTION PERIOD</strong>
<p>We will retain your personal information for as long as needed or permitted in light of the purpose(s) for which 
it was obtained.  The criteria used to determine our retention periods include: (i) the length of time 
we have an ongoing relationship with you and provide the Service to you; (ii) whether there is a 
legal obligation to which we are subject; and (iii) whether retention is advisable in light of our 
legal position (such as in regard to applicable statutes of limitations, litigation, or regulatory investigations).
</p>
</p>
<p><strong>THIRD PARTY SITES AND SERVICES</strong>
<p>This Service may contain links to sites of third parties. This Privacy Policy does not address, and we are not responsible for, the privacy, information, or practices of any third parties, including any third party operating any site or online service (including, without limitation, any application) that is available through this Service or to which this Service contains a link.  The availability of, or inclusion of a link to, any such site or property on the Service does not imply endorsement of it by us or by our affiliates.
</p>
</p>
<p><strong>CONTACTING US</strong>
<p>Johnson &amp; Johnson Limited, located 50-100 Holmers Farm Way, High Wycombe, Buckinghamshire, HP12 4DP, 
is the company responsible for collection, use, and disclosure of personal information under this Privacy Policy.
If you have any questions about this Privacy Policy, please contact us via email at <a target="_blank" href="https://globalconsumercare.com/ContactUs/s/ContactForm?language=en_US&id=a296N000000Bsi2">email</a>
, by phone on 01344 864 000 between 9.30am-5pm Monday-Friday, or please write to the following address:
</p></p>
<p>Johnson &amp; Johnson Limited<br />
50-100 Holmers Farm Way <br />
High Wycombe <br />
Buckinghamshire, HP12 4DP <br />
</p>
<p>
You may also contact our data protection officer responsible for your country or region, if applicable, at{" "}
<a href="mailto:emeaprivacy@kenvue.com">emeaprivacy@kenvue.com</a>.</p>

<p><strong>LODGING A COMPLAINT WITH A REGULATOR</strong>
<p>You may lodge a complaint with a supervisory authority competent for your country or region: 
-	For the European Economic Area, please click here for contact information for such authorities.
-	For the United Kingdom, please contact the UK Information Commissioner’s Office at{" "}
<a href="mailto:ico.org.uk">https://ico.org.uk/</a>.
</p>
</p>
<p>
<strong>UPDATES TO THIS PRIVACY POLICY</strong>
<p>We may change this Privacy Policy.  Any changes to this Privacy Policy 
will become effective when we post the revised Privacy Policy on the Service.  
Your use of the Service following these changes means that you accept the revised Privacy Policy.  
We recommend that you regularly review the Privacy Policy when you visit the Service. 
This policy was last updated on 4 May 2023.
</p>
</p>
                
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.instagram.com/PizBuin_UK/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n/* <![CDATA[ */\nvar google_conversion_id = 924078965;\nvar google_custom_params = window.google_tag_params;\nvar google_remarketing_only = true;\n/* ]]> */\n"
          }}
        />
        <script
          type="text/javascript"
          src="//www.googleadservices.com/pagead/conversion.js"
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <div style="display:inline">\n            <img height="1" width="1" style="border-style:none" alt="" src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/924078965/?value=0&amp;guid=ON&amp;script=0">\n         </div>\n      '
          }}
        />
      </div>
    );
  }
}

export default Page;
